import Vue from 'vue';
const drag = Vue.directive('drag', {
  bind: function(el) { console.log(el); },
  // inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
  inserted: function(el) {
    el.onmousedown = function(e) {
      var disx = e.pageX - el.offsetLeft;
      var disy = e.pageY - el.offsetTop;
      document.onmousemove = function(e) {
        el.style.left = e.pageX - disx + 'px';
        el.style.top = e.pageY - disy + 'px';
      };
      document.onmouseup = function() {
        document.onmousemove = document.onmouseup = null;
      };
    };
  },
  // 当VNode更新的时候会执行updated，可以触发多次
  updated: function(el) { console.log(el); }
});
export default drag;
