import axios from 'axios';
import { Loading, Message } from 'element-ui';

axios.defaults.timeout = 10000000; // 超时时间
axios.defaults.withCredentials = true;

var loadinginstace;
axios.interceptors.request.use(config => { // http 请求拦截器
  if (config.params && !config.params.noLoad) {
    loadinginstace = Loading.service({ fullscreen: true });
  }
  return config;
}, error => {
  loadinginstace ? loadinginstace.close() : '';
  Message.error({
    message: '请求超时'
  });
  return Promise.reject(error);
});

axios.interceptors.response.use(rej => { // http 响应拦截器
  loadinginstace ? loadinginstace.close() : '';
  return rej;
}, error => {
  loadinginstace ? loadinginstace.close() : '';
  return Promise.reject(error);
});
export default axios;
