import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const App = () => import('@/components/App.vue');
const Home = () => import('@/components/Home.vue');
const User = () => import('@/components/User/User.vue');
const Address = () => import('@/components/User/Address.vue');
const WorkOrder = () => import('@/components/User/WorkOrder.vue');
const AddWorkOrder = () => import('@/components/User/AddWorkOrder.vue');
const updataPassword = () => import('@/components/User/updataPassword.vue');
const accountbalance = () => import('@/components/User/accountbalance.vue');
const FreightRate = () => import('@/components/FreightRate/FreightRate.vue');
const FreightDetails = () => import('@/components/FreightRate/FreightDetails.vue');
const GoOrder = () => import('@/components/FreightRate/GoOrder.vue');
const ServiceDetails = () => import('@/components/FreightRate/ServiceDetails.vue');
const Commodity = () => import('@/components/Commodity/Commodity.vue');
const AddCommodity = () => import('@/components/Commodity/AddCommodity.vue');
const Nofined = () => import('@/components/Nofined.vue');
const Login = () => import('@/components/Login/Login.vue');
const Register = () => import('@/components/Login/Register.vue');
const Reset = () => import('@/components/Login/Reset.vue');
const orderlist = () => import('@/components/order/orderlist.vue');
const orderdetails = () => import('@/components/order/orderdetails.vue');
const commoditydetails = () => import('@/components/order/commoditydetails.vue');
const editorder = () => import('@/components/order/editorder.vue');
const financelist = () => import('@/components/finance/financelist.vue');
const monthlystatement = () => import('@/components/finance/monthlystatement.vue');
const financedetails = () => import('@/components/finance/financedetails.vue');
const monthlydetails = () => import('@/components/finance/monthlydetails.vue');
const routes = [
  { path: '/', component: App,
    children: [
      { path: '/', component: Home },
      { path: '/home', component: Home },
      { path: '/freightRate', name: 'freightRate', component: FreightRate },
      { path: '/freightRate/details', component: FreightDetails },
      { path: '/freightRate/goOrder', name: 'GoOrder', component: GoOrder },
      { path: '/freightRate/serviceDetails', component: ServiceDetails },
      { path: '/commodity/addCommodity', name: 'commodityAddCommodity', component: AddCommodity },
      { path: '/commodity/editCommodity', component: AddCommodity },
      { path: '/commodity', component: Commodity },
      { path: '/user', component: User },
      { path: '/user/address', component: Address },
      { path: '/user/workOrder', component: WorkOrder },
      { path: '/user/addWorkOrder', component: AddWorkOrder },
      { path: '/user/updataPassword', component: updataPassword },
      { path: '/user/accountbalance', name: 'accountbalance', component: accountbalance },
      { path: '/orderlist', name: 'orderlist', component: orderlist },
      { path: '/orderdetails', name: 'orderdetails', component: orderdetails },
      { path: '/commoditydetails', name: 'commoditydetails', component: commoditydetails },
      { path: '/financelist', name: 'financelist', component: financelist },
      { path: '/financedetails', name: 'financedetails', component: financedetails },
      { path: '/monthlystatement', name: 'monthlystatement', component: monthlystatement },
      { path: '/monthlydetails', name: 'monthlydetails', component: monthlydetails },
      { path: '/editorder', name: 'editorder', component: editorder, meta: { keepAlive: true, isBack: false }}
    ]
  },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/reset', component: Reset },
  { path: '*', component: Nofined }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => { // 页面权限检测
  // 如果是登录页，直接放行
  if (to.path == '/login') return next();
  if (to.path == '/register') return next();
  if (to.path == '/reset') return next();
  // 判断是否已经登录过,未登陆过，强制去登录
  if (!window.sessionStorage.isLogin) {
    return next('/login');
  }
  if (from.name == 'commoditydetails') {
    to.meta.isBack = true;
  }
  next();
});
export default router;
