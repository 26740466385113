
import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);

const moduleA = {
  state: { names: '45' },
  mutations: { },
  actions: { },
  getters: { }
};

const store = new Vuex.Store({
  state: { // 状态
    name: '用户名',
    menu: [
      { id: '1', name: '首页', router: '/home', child: [] },
      { id: '2', name: '报价查询', router: '/freightRate', child: [] },
      { id: '3', name: '订单管理', router: '/orderlist', child: [
        // {id:'3-1',name:'订单管理',router:"/orderlist",hidden:true},
        // {id:'3-2',name:'订单编辑',router:"/editorder",hidden:true},
        // {id:'3-3',name:'查看详情',router:"/orderdetails",hidden:true},
      ] },
      { id: '4', name: '财务管理', router: '/financelist', child: [
        { id: '4-1', name: '财务管理', router: '/financelist', hidden: true },
        { id: '4-2', name: '月度账单', router: '/monthlystatement' },
        { id: '4', name: '账单列表', router: '/financelist' },
        { id: '4-3', name: '账单编辑', router: '/financedetails', hidden: true },
        { id: '4-4', name: '月账单编辑', router: '/monthlydetails', hidden: true }
      ] },
      { id: '5', name: '商品管理', router: '/commodity', child: [
        // {id:'5-1',name:'商品管理',router:"/commodity",hidden:true},
        // {id:'5-2',name:'新建商品',router:"/commodity/addCommodity",hidden:true},
      ] },
      { id: '6', name: '个人中心', router: '/user', child: [
        { id: '6-3', name: '修改密码', router: '/user/updataPassword' },
        { id: '6-1', name: '个人中心', router: '/user', hidden: true },
        { id: '6-2', name: '地址维护', router: '/user/address' },
        { id: '6-4', name: '我的工单', router: '/user/workOrder' },
        { id: '6-5', name: '创建工单', router: '/user/addWorkOrder', hidden: true },
        { id: '6-6', name: '账户余额', router: '/user/accountbalance' }
      ] }
    ],
    country: [], // 国家下拉
    freight: [], // 运输方式下拉
    commodity: {}, // 编辑的商品
    userInfo: {}, // 用户信息
    addType: 1, // 提货地址1 收货地址2
    WorkOrder: {}, // 查看详情工单回填
    packingList: null
  },
  getters: { // 计算

  },
  mutations: { // 事件 commit
    newFile(state, data) { // 设置文件对象
      state.EGoOrder = data;
    },
    newName(state, data) { // 设置名称
      state.name = data;
    },
    newWork(state, data) { // 设置工单数据
      state.WorkOrder = data;
    },
    newMenu(state, data) { // 设置菜单
      state.menu = data;
    },
    newCountry(state, data) { // 设置国家下拉
      state.country = data;
    },
    newFreight(state, data) { // 设置运输方式下拉
      state.freight = data;
    },
    newCommodity(state, data) { // 设置需要编辑的商品
      state.commodity = data;
    },
    setNameInfo(state, data) { // 设置用户信息
      state.userInfo = data;
    },
    setAddType(state, data) { // 设置地址类型
      state.addType = data;
    },
    setPackingList(state, data) { // 设置地址类型
      state.packingList = data;
    }

  },
  actions: { // 行为 dispatch
    newFile: function(context, data) {
      context.commit('newFile', data);
    },
    newName: function(context, data) {
      context.commit('newName', data);
    },
    newWork: function(context, data) { // 查看工单数据
      context.commit('newWork', data);
    },
    newCountry: function(context, data) { // 设置国家列表
      context.commit('newCountry', data);
    },
    newFreight: function(context, data) { // 设置运输列表
      context.commit('newFreight', data);
    },
    newCommodity: function(context, data) { // 设置运输列表
      context.commit('newCommodity', data);
    },
    setNameInfo: function(context, data) { // 设置用户信息
      context.commit('setNameInfo', data);
    },
    setAddType: function(context, data) { // 设置地址类型
      context.commit('setAddType', data);
    }

  },
  modules: {
    one: moduleA
  }
});

export default store;
