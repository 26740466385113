import Vue from 'vue';
import axios from '@/config/axios';
import vueAxios from 'vue-axios';
import router from '@/config/router';
import store from '@/store';
import ElementUI from 'element-ui';
import drag from '@/utils/drag';
import { registerAllModules } from 'handsontable/registry';
import 'element-ui/lib/theme-chalk/index.css';
import 'handsontable/dist/handsontable.full.min.css';
import '@/config/common.css';
Vue.use(ElementUI);
Vue.use(drag);
Vue.use(vueAxios, axios);
Vue.config.productionTip = false;
const App = () => <router-view></router-view>;
registerAllModules();
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');
